<template>
  <div id="supplier-edit">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />

    <b-modal
      id="delete-supplier-modal"
      v-model="showModal"
      title="Supplier Deletion Confirmation"
      @ok="doubleDeleteModal(true)"
    >
      <p class="my-2">Are you sure you would like to delete this supplier?</p>
    </b-modal>

    <b-modal
      id="double-delete-supplier-modal"
      v-model="showDoubleModal"
      ref="my-modal"
      title="Double Deletion Confirmation"
      @ok="confirmedDelete"
      :ok-disabled="blockDelete"
    >
      <p id="countdown">{{ count }}</p>
      <p class="my-2">
        Deleting a supplier could create unexpected errors if it is in use. Please be careful, only
        delete a supplier if you're certain it has never been used. Are you sure you want to delete
        the supplier?
      </p>
    </b-modal>

    <b-container>
      <div class="d-flex justify-content-center mb-3" v-if="loading">
        <Busy primary text="Loading..." />
      </div>
      <div v-else class="col-12">
        <h1>{{ action }} Supplier</h1>

        <!-- Action Button -->
        <b-dropdown class="mb-3" text="Actions">
          <!-- Suppliers Search -->
          <b-dropdown-item :to="{ name: 'get-suppliers' }">
            <font-awesome-icon :icon="['fas', 'table']" class="text-muted mr-2" />Suppliers Search
          </b-dropdown-item>

          <!-- Spotlight rules search -->
          <b-dropdown-item :to="{ name: 'spotlight-rules', query: { q: supplier.name } }">
            <font-awesome-icon :icon="['fas', 'whale']" class="text-muted mr-2" />Spotlight Rules
          </b-dropdown-item>

          <div v-if="isAdmin & isEdit">
            <b-dropdown-divider />

            <!-- Supplier Details -->
            <b-dropdown-item
              v-if="this.routeId"
              :to="{ name: 'supplier-details', params: { supplierId: this.routeId } }"
            >
              <font-awesome-icon :icon="['fas', 'info-circle']" class="text-muted mr-2" />Details
            </b-dropdown-item>

            <!-- Supplier Create -->
            <b-dropdown-item v-if="this.routeId" @click="redirectCreate">
              <font-awesome-icon :icon="['fas', 'file-upload']" class="text-muted mr-2" />New
              supplier
            </b-dropdown-item>

            <!-- Delete Consent List -->
            <b-dropdown-item v-if="this.routeId" @click="deleteHandler()">
              <font-awesome-icon
                :icon="['fas', 'trash']"
                class="mr-1 text-muted space-right"
              />Delete supplier
            </b-dropdown-item>
          </div>
        </b-dropdown>

        <b-form class="mb-4" ref="supplierForm" @submit="onSubmit" @reset="onReset">
          <b-row>
            <b-col md="6">
              <b-form-group label="Name">
                <b-form-input
                  v-model="supplier.name"
                  placeholder="Enter supplier name"
                  required
                ></b-form-input>
              </b-form-group>

              <b-input-group>
                <b-form-group label="Company House Number">
                  <b-form-input
                    v-model="supplier.companyHouseNumber"
                    placeholder="Enter company house number"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Reducer Score" class="ml-4">
                  <b-form-input
                    v-model="supplier.reducerScore"
                    type="number"
                    min="0.0"
                    max="10.0"
                    step="0.1"
                    placeholder="Enter reducer score"
                  ></b-form-input>
                </b-form-group>
              </b-input-group>

              <span>Categories:</span>
              <b-form-checkbox-group
                class="float-right"
                v-model="selected"
                :options="options"
                :disabled="supplier.broker"
              ></b-form-checkbox-group>
            </b-col>

            <b-col cols="12">
              <b-checkbox v-model="supplier.broker">Broker</b-checkbox>
            </b-col>

            <b-col md="6" v-if="isEdit">
              <div class="mb-3">
                <span>Supplier ID:</span>
                <span class="float-right">{{ supplier.supplierId }}</span>
              </div>

              <div class="mb-3">
                <span>Created By:</span>
                <span class="float-right">{{ supplier.createdByEmail }}</span>
              </div>

              <div class="mb-3">
                <span>Updated By:</span>
                <span class="float-right">{{ supplier.lastUpdatedByEmail }}</span>
              </div>

              <div class="mb-3">
                <span>Created Date:</span>
                <span class="float-right">{{ formatDate(supplier.createdDate) }}</span>
              </div>

              <div class="mb-3">
                <span>Updated Date:</span>
                <span class="float-right">{{ formatDate(supplier.updatedDate) }}</span>
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-4">
            <b-col sm="6">
              <b-form-group label="Short Description">
                <b-form-textarea
                  id="textarea"
                  v-model="supplier.shortDescription"
                  placeholder="Enter short description..."
                  rows="3"
                  max-rows="4"
                ></b-form-textarea>
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <b-form-group label="Long Description">
                <b-form-textarea
                  id="textarea-rows"
                  placeholder="Enter a long description"
                  rows="3"
                  v-model="supplier.longDescription"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="my-3">
            <b-col md="12">
              <b-button
                class="toggle-section"
                block
                href="#"
                v-b-toggle.propertiesInputs
                variant="outline-secondary"
              >
                Key Points
                <font-awesome-icon
                  :icon="['fas', 'sort-down']"
                  class="text-muted mr-2 fa-lg float-right"
                />
              </b-button>
              <b-collapse id="propertiesInputs">
                <b-card-body>
                  <b-row>
                    <b-col>
                      <div class="mb-2">
                        <b-button class="p-0 mr-2" variant="success" @click="keyPoints.push('')">
                          <font-awesome-icon
                            class="mx-1"
                            :icon="['fas', 'plus']"
                          ></font-awesome-icon>
                        </b-button>
                        <label class="d-inline-block">Key Points</label>
                      </div>
                      <b-form-group>
                        <div class="mb-2" v-for="(keypoint, index) in keyPoints" v-bind:key="index">
                          <b-input-group>
                            <b-form-input v-model="keyPoints[index]" type="text"></b-form-input>
                            <b-button
                              class="p-0"
                              variant="danger"
                              @click="keyPoints.splice(index, 1)"
                            >
                              <font-awesome-icon
                                class="mx-2"
                                :icon="['fas', 'trash']"
                              ></font-awesome-icon>
                            </b-button>
                          </b-input-group>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-collapse>
            </b-col>
          </b-row>

          <b-row class="my-3">
            <b-col md="12">
              <b-button
                class="toggle-section"
                block
                href="#"
                v-b-toggle.socialMediaInputs
                variant="outline-secondary"
              >
                Social Media Details
                <font-awesome-icon
                  :icon="['fas', 'sort-down']"
                  class="text-muted mr-2 fa-lg float-right"
                />
              </b-button>
              <b-collapse id="socialMediaInputs" visible>
                <b-card-body>
                  <b-row>
                    <b-col sm="6">
                      <b-form-group label="Twitter">
                        <b-form-input
                          v-model="socialMedia.twitterUrl"
                          placeholder="Enter Twitter url"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group label="Facebook">
                        <b-form-input
                          v-model="socialMedia.facebookUrl"
                          placeholder="Enter Facebook url"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="6">
                      <b-form-group label="Website">
                        <b-form-input
                          v-model="socialMedia.websiteUrl"
                          placeholder="Enter website url"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group label="LinkedIn">
                        <b-form-input
                          v-model="socialMedia.linkedInUrl"
                          placeholder="Enter LinkedIn url"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-collapse>
            </b-col>
          </b-row>

          <b-row class="my-3">
            <b-col md="12">
              <b-button
                class="toggle-section mt-1"
                block
                href="#"
                v-b-toggle.trustPilotInputs
                variant="outline-secondary"
              >
                Trust Pilot Details
                <font-awesome-icon
                  :icon="['fas', 'sort-down']"
                  class="text-muted mr-2 fa-lg float-right"
                />
              </b-button>

              <b-collapse id="trustPilotInputs" visible>
                <b-card-body>
                  <b-row>
                    <b-col sm="6">
                      <b-form-group label="URL">
                        <b-form-input
                          v-model="trustPilot.url"
                          placeholder="Enter trust pilot url"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="3">
                      <b-form-group label="Score">
                        <b-form-input
                          v-model="trustPilot.score"
                          type="number"
                          min="0.0"
                          max="5.0"
                          step="0.1"
                          placeholder="Enter trust pilot score"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="3">
                      <b-form-group label="Reviews Nr.">
                        <b-form-input
                          v-model="trustPilot.reviews"
                          type="number"
                          min="0"
                          placeholder="Enter number of reviews"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-collapse>
            </b-col>
          </b-row>

          <hr />
          <b-row class="mt-4">
            <b-col sm="2">
              <span>Supplier logo:</span>
            </b-col>

            <b-col sm="2">
              <b-form-file
                class="float-right d-inline"
                v-model="logos3url"
                accept="image/png"
              ></b-form-file>
            </b-col>

            <b-col sm="2">
              <span>Supplier square logo:</span>
            </b-col>

            <b-col sm="2">
              <b-form-file v-model="squarelogos3url" accept="image/png"></b-form-file>
            </b-col>

            <b-col sm="2">
              <span>Supplier favicon:</span>
            </b-col>

            <b-col sm="2">
              <b-form-file v-model="faviconurl" accept="image/icon"></b-form-file>
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="4">
              <div class="image">
                <b-img id="logos3url" :src="this.supplier.logos3url" fluid v-if="isEdit"></b-img>
              </div>
            </b-col>
            <b-col sm="4">
              <div class="image">
                <b-img
                  id="squarelogos3url"
                  :src="this.supplier.squarelogos3url"
                  fluid
                  v-if="isEdit"
                ></b-img>
              </div>
            </b-col>
            <b-col sm="4">
              <div class="image">
                <b-img id="faviconurl" :src="this.supplier.faviconurl" fluid v-if="isEdit"></b-img>
              </div>
            </b-col>
          </b-row>

          <b-row class="my-5 float-right">
            <b-button variant="primary" @click.stop="onSubmit">Submit</b-button>&nbsp;
            <b-button variant="outline-secondary" class="ml-2" @click.stop="onReset"
              >Clear Changes</b-button
            >
          </b-row>
        </b-form>
      </div>
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
#supplier-edit {
  .image {
    text-align: center;
  }

  .toggle-section.collapsed,
  .toggle-section.collapsed:hover,
  .toggle-section:hover {
    background-color: $color-font-para;
    border-color: $color-font-para;
    color: white;
    svg {
      color: white !important;
    }
  }

  #countdown {
    font-size: 25px;
    font-style: "bold";
    text-align: center;
  }
}
</style>

<script>
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import CognitoAuth from "@/cognito/cognitoauth";
import { EventBus } from "@/components/eventbus";
import FormatHelper from "@/helper/formathelper";
import SupplyTypes from "@/helper/supplytypehelper";
import Busy from "@/components/Busy";

export default {
  name: "SupplierUpdate",
  components: { Busy },
  data() {
    return {
      title: "Supplier Update",
      isAdmin: false,
      isEdit: false,
      loading: true,
      action: null,
      options: [],
      selected: [],
      routeId: null,

      supplier: { keyPoints: [] },
      socialMedia: [
        { twitterUrl: null },
        { facebookUrl: null },
        { websiteUrl: null },
        { linkedInUrl: null }
      ],
      trustPilot: [{ url: null }, { score: 0 }, { reviews: 0 }],
      keyPoints: [],

      showModal: false,
      showDoubleModal: false,
      blockDelete: true,
      count: 0,
      squarelogos3url: null,
      logos3url: null,
      faviconurl: null
    };
  },
  watch: {
    //In order to preview the selected logo
    squarelogos3url() {
      this.supplier.squarelogos3url = URL.createObjectURL(this.squarelogos3url);
    },
    logos3url() {
      this.supplier.logos3url = URL.createObjectURL(this.logos3url);
    },
    faviconurl() {
      this.supplier.faviconurl = URL.createObjectURL(this.faviconurl);
    }
  },

  created() {
    this.refreshAdmin();
    this.options = this.createCategoryOptions();
    this.routeId = this.$route.params.supplierId;
    this.fetchData();
  },
  methods: {
    formatDate: FormatHelper.formatDateTimeToSec,
    formatCoboldCase: FormatHelper.formatCoboldCase,
    supplyTypes: SupplyTypes.supplyTypes,
    supplyTypeDisplay: SupplyTypes.toDisplay,

    createCategoryOptions() {
      var options = [];
      this.supplyTypes().forEach(category => {
        var option = {};
        option["value"] = category;
        option["text"] = this.supplyTypeDisplay(category);
        options.push(option);
      });
      return options;
    },
    async refreshAdmin() {
      this.isAdmin = await CognitoAuth.isCurrentUserAdmin();
    },

    async fetchData() {
      this.resetData();
      this.routeId = this.$route.params.supplierId;
      if (!this.routeId) {
        this.isEdit = false;
        this.action = "Create";
        this.loading = false;
      } else {
        this.isEdit = true;
        this.action = "Edit";
        this.getSupplierData(this.routeId);
      }
    },

    async getSupplierData(supplierIds) {
      var summarised = false;

      try {
        const client = await ApiHelper.http();
        const response = await client.get(
          `${ApiHelper.endPoint()}suppliers?summarised=${summarised}&supplierIds=${supplierIds}`
        );
        if (response.data.status === "success") {
          var firstElement = Object.keys(response.data.suppliers)[0];
          this.supplier = Object.assign({}, response.data.suppliers[firstElement]);

          this.supplier["keyPoints"] = [];

          this.supplier.supplierId = response.data.suppliers[firstElement].supplierId;

          if (response.data.suppliers[firstElement].categories) {
            this.selected = response.data.suppliers[firstElement].categories;
          }
          if (response.data.suppliers[firstElement].socialMedia) {
            this.socialMedia = response.data.suppliers[firstElement].socialMedia;
          }
          if (response.data.suppliers[firstElement].trustPilot) {
            this.trustPilot = response.data.suppliers[firstElement].trustPilot;
            if (!this.trustPilot.score) this.trustPilot.score = 0;
            if (!this.trustPilot.reviews) this.trustPilot.reviews = 0;
          }
          if (response.data.suppliers[firstElement].keyPoints) {
            response.data.suppliers[firstElement].keyPoints.forEach(keyPoint =>
              this.keyPoints.push(keyPoint)
            );
          }
          Console.log("Supplier: ", response);
        } else {
          this.showMessage("A problem occured while extracting the supplier data.", "warning");
        }
      } catch (error) {
        this.showMessage("A problem occured while extracting the supplier data.", "warning");
        Console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async postSupplierData() {
      let dataObject = new Object();
      dataObject = this.createDataObject();

      try {
        const client = await ApiHelper.http();
        const response = await client.post(`${ApiHelper.endPoint()}suppliers`, dataObject);
        Console.log("Response: ", response);

        if (response.data.status === "success") {
          this.showMessage("Supplier details saved successfully.", "success");
          this.supplier.supplierId = response.data.supplier.supplierId;
          this.postSupplierFiles();
        } else {
          this.showMessage("There was a problem saving supplier details.", "warning");
        }
      } catch (error) {
        Console.error(error);
        this.showMessage("There was a problem saving supplier details.", "warning");
      }
    },

    async postSupplierFiles() {
      var fileFormData = new FormData();
      fileFormData = this.createFileFormData();

      try {
        const client = await ApiHelper.http();
        const response = await client.post(`${ApiHelper.endPoint()}suppliers/files`, fileFormData);
        Console.log("Response Files: ", response);

        if (response.data.status === "success") {
          this.showMessage("Supplier files saved successfully.", "success");
          this.supplier.supplierId = response.data.supplierId;
          this.selected = [];

          this.$router.push({
            name: "supplier-details",
            params: { supplierId: response.data.supplier.supplierId }
          });

          this.fetchData();
        } else {
          this.showMessage("There was a problem saving supplier files.", "warning");
        }
      } catch (error) {
        Console.error(error);
        this.showMessage("There was a problem saving supplier files.", "warning");
      }
    },

    //BUTTONS
    async onSubmit(evt) {
      evt.preventDefault();
      if (this.$refs.supplierForm.checkValidity()) {
        this.postSupplierData();
      } else {
        this.$refs.supplierForm.reportValidity();
      }
    },

    onReset() {
      this.selected = null;
      this.keyPoints = [];
      if (!this.routeId) {
        this.resetData();
      } else {
        this.logos3url = null;
        this.squarelogos3url = null;
        this.faviconurl = null;
        this.getSupplierData(this.routeId);
      }
    },

    //DELETE SUPPLIER BY ID
    countDownTimer(countdown) {
      this.blockDelete = true;
      this.count = 10;
      if (countdown > 0) {
        setTimeout(() => {
          countdown -= 1;
          this.countDownTimer(countdown);
        }, 1000);
        this.count = countdown;
      } else {
        this.blockDelete = false;
      }
    },

    doubleDeleteModal(value) {
      this.showDoubleModal = value;

      if (value == true) {
        this.countDownTimer(10);
      }
    },

    deleteHandler() {
      this.showModal = true;
    },

    async confirmedDelete() {
      try {
        const client = await ApiHelper.http();
        let response = await client.delete(`${ApiHelper.endPoint()}suppliers/${this.routeId}`);
        if (response.data.status === "success") {
          this.$router.push({ name: "get-suppliers" });
        } else {
          this.showMessage("There was a problem deleting the supplier.", "warning");
        }
      } catch (error) {
        Console.error(error);
        this.showMessage("There was a problem deleting the supplier.", "warning");
      }
    },

    async redirectCreate() {
      await this.$router.push({ name: "admin-supplier-create" });
      this.fetchData();
    },

    resetData() {
      var form = {};
      form.supplierId = null;
      form.name = null;
      form.shortDescription = null;
      form.longDescription = null;
      form.countryHouseNumber = null;
      form.reducerScore = null;
      form.categories = [];
      form.logos3url = null;
      form.squarelogos3url = null;
      form.faviconurl = null;
      form.lastUpdatedByEmail = null;
      form.createdByEmail = null;
      form.createdId = null;
      form.updatedId = null;
      form.keyPoints = [];
      this.supplier = form;

      form = {};
      form.score = 0;
      form.url = null;
      form.reviews = 0;
      this.trustPilot = form;

      form = {};
      form.facebookUrl = null;
      form.twitterUrl = null;
      form.linkedInUrl = null;
      form.websiteUrl = null;
      this.socialMedia = form;

      this.selected = [];
      this.keyPoints = [];
    },

    //PREPARING DATA

    createFileFormData() {
      const formData = new FormData();

      Console.log("Creating files: ", this.supplier);
      if (this.supplier.supplierId) {
        formData.append("supplierId", this.supplier.supplierId);
      }
      //APPEND NEW LOGOS
      if (this.logos3url) {
        formData.append("logo", this.logos3url, this.logos3url.name);
      }
      if (this.squarelogos3url) {
        formData.append("squarelogo", this.squarelogos3url, this.squarelogos3url.name);
      }
      if (this.faviconurl) {
        formData.append("favicon", this.faviconurl, this.faviconurl.name);
      }

      return formData;
    },

    createDataObject() {
      let form = new Object();
      form.socialMedia = this.socialMedia;

      //SOCIAL MEDIA
      if (!this.trustPilot.score) {
        this.trustPilot.score = 0;
      }
      if (!this.trustPilot.reviews) {
        this.trustPilot.reviews = 0;
      }
      form.trustPilot = this.trustPilot;

      //UPDATE KEYPOINTS
      if (typeof this.supplier.keyPoints != "undefined") {
        this.supplier.keyPoints = [];
      }
      this.keyPoints.forEach(keyPoint => {
        if (keyPoint && keyPoint.length != 0) {
          this.supplier["keyPoints"].push(keyPoint);
        }
      });

      //UPDATE CATEGORIES
      if (this.selected && this.selected.length > 0) {
        for (let i = 0; i < this.selected.length; i++) {
          this.selected[i] = this.toSupplyTypeEnum(this.selected[i]);
        }
      }
      if (typeof this.supplier.categories != "undefined") {
        this.supplier.categories = [];
      }
      this.supplier.categories = this.selected;

      form.supplier = this.supplier;

      return form;
    },

    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    },

    toSupplyTypeEnum(category) {
      if (category != null) {
        if (category == "Other") {
          return "CUSTOM";
        }
        return category
          .split(" ")
          .join("_")
          .toUpperCase();
      }
    }
  }
};
</script>
